import { MenuOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import { arrayMoveImmutable } from 'array-move'
import React, { memo } from 'react'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc'
import { TableColumnProps } from './AppTable'
import ClickableText from '@components/ClickableText'

interface ISortableHandleElement {
  children: React.ReactNode
  className?: string
}

interface ISortableItem extends SortableElementProps {
  children: React.ReactNode
  className?: string
}

interface ISortableContainer extends SortableContainerProps {
  children: React.ReactNode
  className?: string
}

const DragHandle: React.ComponentClass<ISortableHandleElement, any> = SortableHandle(({ children, className }) => (
  <div className={className || ''}>{children}</div>
))
const SortableItem: React.ComponentClass<ISortableItem, any> = SortableElement(({ children, className }) => (
  <div className={className || ''}>{children}</div>
))
const SortableBody: React.ComponentClass<ISortableContainer, any> = SortableContainer(({ children, className }) => (
  <div className={className || ''}>{children}</div>
))
interface TableSettingProps<T = any> {
  columns: TableColumnProps<T>[]
  onChange: Function
  setDefaultColumns: Function
  onClose: Function
}

const ColumnItem = ({ value, index, toggleColumnVisible }) => (
  <SortableItem className="dragging-item" key={`show-item-${value.name ?? value.title}`} index={index}>
    <DragHandle className="dragging-item-trigger">
      <MenuOutlined style={{ cursor: 'grab', color: 'white' }} />
    </DragHandle>
    <div className="dragging-item-content flex justify-between w-full py-1 select-none">
      <span>{value.title}</span>
      <Checkbox checked={!value.hidden} className="" onChange={() => toggleColumnVisible(index)} />
    </div>
  </SortableItem>
)

const TableSetting = ({ columns, onChange, setDefaultColumns, onClose }: TableSettingProps) => {
  const toggleColumnVisible = columIndex => {
    if (columIndex >= 0) {
      columns[columIndex].hidden = !columns[columIndex].hidden
      onChange(columns)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      onChange(arrayMoveImmutable(columns, oldIndex, newIndex))
    }
  }

  const showHideAll = isShowAll => {
    const checkHasHidden = columns.findIndex(t => (t.hidden ?? false) === isShowAll)
    if (checkHasHidden > -1) {
      columns
        .filter(t => (t.hidden ?? false) === isShowAll)
        .forEach(item => {
          item.hidden = !isShowAll
        })
      onChange(columns)
    }
  }

  return (
    <div className="bg-white p-2">
      <div className="flex justify-between items-center sticky top-0 bg-white z-10">
        <div className="text-xl font-medium pt-2">View Option</div>
        <ClickableText className="mt-2" onClick={onClose}>
          Close
        </ClickableText>
      </div>
      <Button color="gray" className="w-full my-2 px-2" onClick={() => setDefaultColumns()}>
        Default Columns
      </Button>

      <SortableBody
        onSortEnd={onSortEnd}
        useDragHandle
        lockAxis="y"
        lockToContainerEdges={true}
        disableAutoscroll
        className="dragging-body"
      >
        <div className="flex justify-between">
          <p style={{ color: 'darkgray' }}>Shown</p>
          <div className="flex gap-2">
            <div className="btn-show-all underline" onClick={() => showHideAll(true)}>
              Show All
            </div>
            <div className="btn-hide-all underline" onClick={() => showHideAll(false)}>
              Hide All
            </div>
          </div>
        </div>
        {columns.map((value, index) => (
          <ColumnItem value={value} index={index} toggleColumnVisible={toggleColumnVisible} />
        ))}
      </SortableBody>
    </div>
  )
}

export default TableSetting
