import appStore from '@stores/appStore'
import { FROM_MODULE_TYPE, IME_GROUP_TASK, MN_GROUP_TASK, MR_GROUP_TASK, SUPP_GROUP_TASK } from '@utils/constants'
import Validator from '@utils/validator'
import { notification } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import _, { isNil, uniqBy } from 'lodash'
import { action, observable } from 'mobx'
import moment from 'moment-timezone'
import { CASE_STATUS, MVA_GROUP_TASK } from './constants'
import { messageError, showNotification } from './errors'
import { getSpecialistProviderNo } from '@stores/util'
// import * as SparkMD5 from 'spark-md5'
/**
 * Check value input is existed not null, undefined or empty
 * @param {any} value
 * @returns boolean
 */
export const isExist = value => !isNil(value)

export function getDateTimestamp(date) {
  return +date.match(/\d+/)[0]
}

export function validateErrorForm(value, type) {
  const v = new Validator({ value }, { value: type })
  if (v.passes()) {
    return null
  } else {
    return type
  }
}

export function getMessagesErrorForm(typeError, typeMSG, fieldName) {
  if (typeError && typeMSG && fieldName) {
    const field = typeError === 'max:10' ? 'max' : typeError === 'min:6' ? 'min' : typeError
    return messageError[field]
  }
  return null
}

export function formatDate(dateStr, includeTime) {
  if (!dateStr) return ''
  includeTime = includeTime || false

  if (dateStr.startsWith('/Date(-')) {
    dateStr = -getDateTimestamp(dateStr)
  } else if (dateStr.startsWith('/Date(')) {
    dateStr = getDateTimestamp(dateStr)
  }

  const options: any = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  const newDate = new Date(dateStr).toLocaleDateString('en-GB', options)
  let [day, month, year, time] = newDate.split(' ')
  year = year.replace(',', '')

  return !includeTime ? `${day} ${month}, ${year}` : `${day} ${month} ${year}, ${time}`
}

export function calcPages(currentPage, totalPage) {
  const range = Math.min(totalPage, 10)
  const pages = []

  const start = Math.min(totalPage + 1 - range, Math.max(1, currentPage - ((range / 2) | 0)))

  const end = start + range
  for (let i = start; i < end; i++) {
    pages.push(i)
  }

  return pages
}

export function convertPhoneFaxNumber(value) {
  if (value) {
    const valueLength = value.length
    if (valueLength < 3) {
      return value
    } else if (valueLength >= 3 && valueLength <= 6) {
      return `${value.slice(0, 2)}-${value.slice(2, 6)}`
    } else if (valueLength > 6 && valueLength <= 10) {
      return `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}`
    }
    return `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}-${value.slice(10)}`
  }
  return value
}

export const ReplaceFileNameUpload = (
  serviceType,
  caseNumber,
  claimantFullname,
  doctorTitle,
  doctorFullName,
  inputDate,
  isRequireVideo,
  clinicName,
  number,
) => {
  var retStr = claimantFullname + ' - ' + doctorTitle + '.' + doctorFullName + ' - ' + inputDate
  switch (serviceType) {
    case 'Assessment':
      retStr += ' - '
      if (isRequireVideo) {
        retStr += 'telehealth'
      } else {
        retStr += clinicName
      }
      retStr += ' - Case No:' + caseNumber
      if (number > 0) {
        retStr += ' - ' + number
      }
      break
    case 'Clinical':
      retStr += ' - '
      retStr += clinicName
      retStr += ' - Case No:' + caseNumber
      if (number > 0) {
        retStr += ' - ' + number
      }
      break
    case 'Supplementary':
    case 'FileReview':
      retStr = retStr + ' - Case No: ' + caseNumber
      if (number > 0) {
        retStr += ' - ' + number
      }
      break
    default:
      break
  }
  return retStr
}

export const convertCamelCaseToUrl = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
export function toCamelCase(text) {
  // Split the text into words
  const words = text.split(' ')

  // Capitalize the first letter of each word including the first one
  const camelCaseText = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('')

  return camelCaseText
}
export const getQueryParams = (routerSearch?, routerState?): any => {
  const search = new URLSearchParams(routerSearch || window.location.search)
  const state = routerState
  const params = {}
  for (let p of search.entries()) {
    params[p[0]] = p[1]
  }
  return {
    ...params,
    ...state,
  }
}

export const setRouteParams = params => {
  const queryParams = new URLSearchParams(window.location.search)
  Object.keys(params).forEach(key => {
    queryParams.set(key, params[key])
  })

  window.history.pushState(null, null, '?' + queryParams.toString())
}

export const checkIsJSON = value => {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}

export const formatReqDate = date => {
  if (!date) {
    return null
  } else if (typeof date === 'string') {
    return dayjs(date).tz(window.APP_CONFIG.timeZone).toISOString()
  }
  return date.toISOString()
}

export const itemToArray = (item = {}, arr = [], key) => {
  return uniqBy([item, ...arr], key).filter(i => !!i?.[key])
}

export const removeAllURLParams = () => {
  if (window.history.pushState) {
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }
}

export const updateURLParams = (params = {}, { append = false } = {}) => {
  params = JSON.parse(JSON.stringify(params))
  let queryParams = new URLSearchParams()
  if (append) {
    queryParams = new URLSearchParams(window.location.search)
  }
  Object.keys(params).forEach(key => {
    const value = params[key]
    if ((typeof value !== 'number' && !value) || value === 'All') {
      if (typeof value !== 'boolean') {
        queryParams.delete(key)
      } else {
        queryParams.set(key, value.toString())
      }
    } else {
      queryParams.set(key, value)
    }
  })
  window.history.replaceState('', null, '?' + queryParams.toString())
}

export const getURLParams = () => {
  let params: any = {}
  if (window.location.pathname !== '/signin-oidc') {
    const queryParams = new URLSearchParams(window.location.search)
    params = Object.fromEntries(queryParams)
  }
  return params
}

export const convertActive = item => {
  switch (typeof item) {
    case 'boolean':
      return item
    case 'string':
      if (item === '' || item == null) {
        return null
      }
      if (item === 'true' || item === '0') {
        return true
      }
      if (item === 'false' || item === '1') {
        return false
      }
      break
    default:
      return null
  }
}

export const showNotificationSuccess = (text = null) => {
  notification.destroy()
  notification.success({
    message: 'Success',
    description: text || 'Data has been saved successfully',
    duration: 3,
  })
}
export const showNotificationError = (data?: any) => {
  if (!data) return

  let errorMessage = ''

  if (typeof data === 'string') {
    errorMessage = data
  }
  if (typeof data === 'object' && data.response) {
    const response = data?.response

    if (typeof response.data === 'string') {
      errorMessage = response.data
    } else if (response.data?.error?.validationErrors?.length > 0) {
      errorMessage = data.response.data.error.validationErrors[0].message
    } else if (data?.response?.data?.error?.message) {
      errorMessage = data.response.data.error.message
    }
  }

  errorMessage = errorMessage ? errorMessage : 'An error occurred, please try again'

  notification.destroy()
  notification.error({
    message: 'Error',
    description: errorMessage,
  })
}

export const showNotificationWarning = text => {
  notification.destroy()
  notification.warning({
    message: 'Warning',
    description: text || 'An error occurred, please try again',
  })
}

export const mapParamsFetchData = data => {
  let params = {}
  Object.keys(data).forEach(key => {
    const value = data[key]
    if ((typeof value === 'number' && value === 0) || value || typeof value === 'boolean') {
      params[key] = value
    }
  })
  return params
}

export const addItemToArray = (item, array, key) => {
  const existItem = array.find(i => i?.[key] === item?.[key])
  if (!existItem) {
    return [...array, item].filter(i => !!i?.[key])
  }
  return [...array.filter(i => i[key] !== item[key]), item].filter(i => !!i?.[key])
}

export const addArrayToArray = (arrF = [], arrayS = [], key) => {
  return [...arrF, ...arrayS.filter(i => !arrF.find(f => f[key] === i[key]))]
}

export const mixSpecialistProviderNoOptions = specialist => {
  const data = getSpecialistProviderNo(specialist, appStore.isCaseManager)
  return data
}

export const getGroupTaskId = ({ listTask = [], groupTask = '' }) => {
  if (!listTask) return null
  return listTask.find(i => i.groupTask === groupTask)?.id
}

export async function getFormData(uploadFile, maxSize) {
  const formData = new FormData()
  for (const element of uploadFile) {
    if (maxSize && element.size > maxSize * 1021000) {
      continue
    }
    formData.append('name', element.name)
    formData.append('file', element)
    formData.append('type', element.type)

    // const md5 = await calculateMD5(element)
  }
  return formData
}

// export async function calculateMD5(file): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()

//     reader.onload = function (event) {
//       const arrayBuffer = event.target.result
//       const spark = new SparkMD5.ArrayBuffer()
//       spark.append(arrayBuffer)
//       const md5 = spark.end()

//       resolve(md5)
//     }

//     reader.onerror = function (event) {
//       reject(event.target.error)
//     }

//     reader.readAsArrayBuffer(file)
//   })
// }

export const getStatus = (fromModule): { [key: string]: any } => {
  if (!fromModule) return {}
  let statuses = { ...CASE_STATUS }

  // Add Prefix
  Object.keys(statuses).forEach(key => {
    const statusName = statuses[key]
    statuses[key] = `${fromModule === FROM_MODULE_TYPE.MR ? 'CR' : fromModule}_${statusName}`
  })

  return statuses
}

export const getGroupTask = (fromModule): { [key: string]: any } => {
  if (!fromModule) return {}
  let rs = {}

  if (fromModule === FROM_MODULE_TYPE.IME) {
    rs = IME_GROUP_TASK
  } else if (fromModule === FROM_MODULE_TYPE.SUPP) {
    rs = SUPP_GROUP_TASK
  } else if (fromModule === FROM_MODULE_TYPE.MR) {
    rs = MR_GROUP_TASK
  } else if (fromModule === FROM_MODULE_TYPE.MN) {
    rs = MN_GROUP_TASK
  } else if (fromModule === FROM_MODULE_TYPE.MVA) {
    rs = MVA_GROUP_TASK
  }

  return rs
}

export const autoRegisterObservable = (instance, overrides = {}) => {
  const observableActions = {}
  Object.getOwnPropertyNames(instance).forEach(name => {
    if (name.startsWith('__private_')) {
      return
    }

    const type = typeof instance[name]
    if (type === 'function') {
      observableActions[name] = action
    } else {
      observableActions[name] = observable
    }
  })
  return { ...observableActions, ...overrides }
}

export function removeUnicode(s) {
  const uniChars =
    'àáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴÂĂĐÔƠƯ'
  const KoDauChars =
    'aaaaaaaaaaaaaaaaaeeeeeeeeeeediiiiiooooooooooooooooouuuuuuuuuuuyyyyyAAAAAAAAAAAAAAAAAEEEEEEEEEEEDIIIOOOOOOOOOOOOOOOOOOOUUUUUUUUUUUYYYYYAADOOU'
  let retVal = ''

  if (s && s.length) {
    for (let i = 0; i < s.length; i++) {
      const pos = uniChars.indexOf(s.charAt(i))

      if (pos >= 0) {
        retVal += KoDauChars.charAt(pos)
      } else {
        retVal += s.charAt(i)
      }
    }
  }

  return retVal
}

export function downloadFileFromResponse(res: any, fileName?: string) {
  const blob = new Blob([res.data], { type: res.headers['content-type'] })
  const url = URL.createObjectURL(blob)
  const a: any = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  if (fileName) {
    a.download = fileName
  }

  a.click()
  window.URL.revokeObjectURL(url)
}

export function numberWithCommas(x: number, fixed: number = 2, defaultFormat: string = '0.00') {
  return !!x
    ? x
        .toFixed(fixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : defaultFormat
    ? defaultFormat
    : 0
}

export const sortArray = (array: any[] = [], sorting: string = 'displayName') => {
  return _.sortBy(array, sorting)
}

export const tripHtmlFromBody = (htmlString = '', maxLength?: number) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = htmlString

  let text = tmp.textContent || tmp.innerText || ''
  if (maxLength) {
    text = text.length > maxLength ? text.substring(0, maxLength) + '...' : text
  }
  return text
}

export const getEventDateTime = (dateString, timeZone): Dayjs | null => {
  if (!dateString) return null

  const date = dayjs(dateString)
  if (moment(dateString).tz(timeZone)?.isDST()) {
    return date.tz(timeZone)
  } else {
    const defaultOffset = moment.tz(timeZone).utcOffset()
    return date.utcOffset(defaultOffset)
  }
}

export const numberDecimalFormatter = (value, prefixValue = '$', suffix = '') => {
  if (value === null || value === undefined) return ''
  prefixValue = prefixValue ? `${prefixValue} ` : ''
  return !!value
    ? prefixValue + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `${suffix}`
    : `${prefixValue} 0` + `${suffix}`
}

export const checkActionRequiredIncomplete = (listActionRequired?) => {
  if (appStore.isSpecialist) return

  const actionIncomplete = (listActionRequired || []).find(i => !i?.completed)
  if (!!actionIncomplete) {
    showNotification('Action Required Reminder', 'Please check and clear pending required actions as needed.', 'info')
    return true
  }
  return false
}

const padToTwo = number =>
  number >= 0 ? (number <= 9 ? `0${number}` : number) : number > -10 && number < 0 ? `-0` + Math.abs(number) : number

export const formatDuration = totalSec => {
  if (!!totalSec) {
    totalSec = Math.abs(totalSec)
    var hour = totalSec >= 3600 ? Math.floor(totalSec / 3600) : 0
    var min = totalSec - hour * 3600 >= 60 ? Math.floor((totalSec - hour * 3600) / 60) : 0
    var sec = totalSec - (hour * 3600 + min * 60)
    return padToTwo(hour) + ':' + padToTwo(min)
  }
  return '00:00'
}

export const getEmailStatus = (
  emaiLStatuses = [],
  templateKey?: string,
  trackingPropertyName?: string,
  fallbackSentTime?: string,
) => {
  let statusItem = null
  if (templateKey && trackingPropertyName) {
    const templateKeys = templateKey.split(',')
    statusItem = emaiLStatuses.find(
      i =>
        templateKeys.includes(i.templateKey) &&
        i.trackingPropertyName &&
        i.trackingPropertyName.toLowerCase() === trackingPropertyName.toLowerCase(),
    )
  } else {
    if (trackingPropertyName) {
      let temp = emaiLStatuses.filter(
        i => i.trackingPropertyName && i.trackingPropertyName.toLowerCase() === trackingPropertyName.toLowerCase(),
      )
      if (temp.length > 1) {
        temp = temp.sort((a, b) => new Date(b.sentTime).getTime() - new Date(a.sentTime).getTime())
      }
      statusItem = temp[0]
    }

    if (templateKey) {
      const templateKeys = templateKey.split(',')
      statusItem = emaiLStatuses.find(i => templateKeys.includes(i.templateKey))
    }
  }

  if (!statusItem) {
    const isOldFeature = fallbackSentTime && dayjs(fallbackSentTime).diff(dayjs('2023/01/01'), 'days') < 0
    if (isOldFeature) return { sentTime: fallbackSentTime }
  }

  return statusItem
}

export const hasEmailTracking = (
  emaiLStatuses = [],
  templateKey?: string,
  trackingPropertyName?: string,
  fallbackSentTime?: string,
) => {
  const emailStatus = getEmailStatus(emaiLStatuses, templateKey, trackingPropertyName, fallbackSentTime)

  if (emailStatus) {
    return true
  } else {
    return false
  }
  // if (emailStatus && emailStatus.status != 2) {
  //   return true
  // } else {
  //   return false
  // }
}

export const isDataChange = (newData: any, oldData) => {
  return !_.isEqual(newData, oldData)
}

export const formatMoney = value => `$ ${numberWithCommas(value, 2)}`
export const formatMoneyWithOutDecimalPoint = value => `$ ${numberWithCommas(value, 0, '0')}`

export const initFavicon = staticImagePath => {
  const iconUrl = process.env.VITE_API_URL + '/files/' + staticImagePath
  var link: any = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  link.href = iconUrl
}

export const convertStringToPixel = subject => {
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')
  ctx.font = '25px Arial'
  let width = Math.ceil(ctx.measureText(subject).width)
  if (width < 500) {
    return 500
  } else if (width > 1500) {
    return 1500
  }
  return width
}

export function isEmpty(value) {
  return value == null || (typeof value === 'string' && value.trim().length === 0)
}

export function getStaticFileUrl(name) {
  return `${process.env.VITE_API_URL}/files/${name}`
}

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop().toLowerCase()
}

export function isEqualObjects(obj1, obj2, ignoreFields: string[] = []) {
  obj1 = _.cloneDeep(obj1)
  obj2 = _.cloneDeep(obj2)

  if (obj1 == null && obj2 == null) {
    return true
  }

  if ((obj1 == '' && obj2 == null) || (obj2 == '' && obj1 == null)) {
    return true
  }

  if ((obj1 == undefined && obj2 == null) || (obj1 == null && obj2 == undefined)) {
    return true
  }

  if (typeof obj1 !== typeof obj2) {
    AppConsole.log('typeof obj1:', typeof obj1, ', typeof obj2: ', typeof obj2)
    return false // Kiểu dữ liệu khác nhau
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false // Kiểu dữ liệu là mảng, nhưng không giống nhau về kiểu hoặc độ dài
    }

    if (obj1.length == 0 && obj2.length == 0) {
      return true
    }

    if (typeof obj1[0] === 'object') {
      // ignore
    } else {
      obj1.sort()
      obj2.sort()

      for (let i = 0; i < obj1.length; i++) {
        if (obj1[i] !== obj2[i]) {
          return false // Giá trị trong mảng khác nhau
        }
      }
    }
  } else if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // if (keys1.length !== keys2.length) {
    //   return false // Số lượng thuộc tính khác nhau
    // }

    for (let key of keys1) {
      if (ignoreFields.includes(key)) {
        continue
      }
      if (!obj2.hasOwnProperty(key)) {
        return false // Thuộc tính không tồn tại trong obj2
      }

      const value1 = obj1[key]
      const value2 = obj2[key]

      if (!isEqualObjects(value1, value2)) {
        return false
      }
    }
  } else {
    if (typeof obj1 === 'string' && typeof obj2 === 'string') {
      if (dayjs(obj1).isValid() && dayjs(obj2).isValid()) {
        if (!dayjs(obj1).isSame(dayjs(obj2))) {
          return false
        }
      }
    }
    if (obj1 != obj2) {
      return false // Giá trị khác nhau
    }
  }

  return true // Không có sự khác biệt
}

export function getComparableCaseStatusLogs(items: any[]) {
  if (!items) return []
  return items.map(i => {
    return `${i.statusName}_${i.isActive ? 'true' : 'false'}${i.statusDate}`
  })
}

export function getComparablePersonInChanges(items: { objectId: string; groupTask: string; userId: string }[]) {
  if (!items) return []
  return items.map(i => {
    return `${i.objectId}_${i.groupTask}_${i.userId}`
  })
}

export function getComparableAttachments(items: { fileId: string }[]) {
  if (!items) return []
  return items.map(i => {
    return `${i.fileId}`
  })
}

/**
 * Format file size to human readable format
 * @param fileSize File size in bytes
 */
export function formatFileSize(fileSize: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024
    unitIndex++
  }
  return `${fileSize.toFixed(2)} ${units[unitIndex]}`.replace('.00', '')
}
