import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/companies'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function GetBusinessUnits(companyId, params = {}) {
  return ApiRequest.get(`${BaseRoute}/${companyId}/businessUnits`, {
    params,
  })
}

function GetBookingHistory(params = {}) {
  return ApiRequest.get(`${BaseRoute}/bookingHistory`, {
    params,
  })
}

function CreateBusinessUnit(companyId, data) {
  queryClient.invalidateQueries([BaseRoute, companyId])
  return ApiRequest.post(`${BaseRoute}/${companyId}/businessUnits`, data)
}

function UpdateBusinessUnit(companyId, businessUnitId, data) {
  queryClient.invalidateQueries([BaseRoute, companyId])
  return ApiRequest.put(`${BaseRoute}/${companyId}/businessUnits/${businessUnitId}`, data)
}

function DeleteBusinessUnit(companyId, businessUnitId) {
  queryClient.invalidateQueries([BaseRoute, companyId])
  return ApiRequest.delete(`${BaseRoute}/${companyId}/businessUnits/${businessUnitId}`)
}

function CheckDuplicate(params) {
  return ApiRequest.get(`${BaseRoute}/isDupplicateName`, { params })
}

function GetSimilarItemsByName(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByName`, { params })
}

function GetFavouriteSpecialists(id, params) {
  return ApiRequest.get(`${BaseRoute}/${id}/favourite-specialists`, { params })
}

function AddFavouriteSpecialists(id, specialistId) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.post(`${BaseRoute}/${id}/favourite-specialists`, { specialistId })
}
function DeleteFavouriteSpecialists(id, specialistId) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`${BaseRoute}/${id}/favourite-specialists/${specialistId}`)
}
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function DownloadUploadCompanyTemplate() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}

function CompanyPMSConnect(pmsConfigId) {
  return ApiRequest.get(`${BaseRoute}/${pmsConfigId}/pms-config-authorize`)
}

function GetPMSConfig(companyId) {
  return ApiRequest.get(`${BaseRoute}/${companyId}/pms-config`)
}
function SavePMSConfig(companyId, data) {
  queryClient.invalidateQueries([BaseRoute, companyId])
  return ApiRequest.post(`${BaseRoute}/${companyId}/pms-config`, data)
}
function PMSSendRequest(pmsConfigId, data) {
  queryClient.invalidateQueries([BaseRoute, pmsConfigId])
  return ApiRequest.post(`${BaseRoute}/${pmsConfigId}/pms-send-request`, data)
}
const API = {
  ...baseFunctions,
  Enable,
  Disable,
  GetBusinessUnits,
  CreateBusinessUnit,
  UpdateBusinessUnit,
  DeleteBusinessUnit,
  GetBookingHistory,
  getSimilarItems: CheckDuplicate,
  GetFavouriteSpecialists,
  AddFavouriteSpecialists,
  DeleteFavouriteSpecialists,
  GetSimilarItemsByName,
  ExportSearchResult,
  DownloadUploadCompanyTemplate,
  UploadFiles,
  GetPMSConfig,
  SavePMSConfig,
  CompanyPMSConnect,
  PMSSendRequest,
}

export default API
