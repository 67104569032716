import { APP_ROUTES } from '@utils/routesConfig'

export const MLP_NOT_ALLOWED_PATHS = [
  APP_ROUTES.fundingRequest.list,
  APP_ROUTES.fundingRequest.add,
  APP_ROUTES.fundingRequest.detail,

  APP_ROUTES.fundingAgreement.list,
  APP_ROUTES.fundingAgreement.add,
  APP_ROUTES.fundingAgreement.detail,

  APP_ROUTES.transactionInvoice.add,
]

export const INSURER_ROLE_ALLOWED_PATHS = [
  '/',
  '/view/login-account',
  APP_ROUTES.fundingTransaction.list,
  APP_ROUTES.fundingTransaction.detail,

  APP_ROUTES.disbursementFunding.list,
  APP_ROUTES.disbursementFunding.detail,
  'view/print',
]

export const CM_NOT_ALLOWED_PATHS = [
  APP_ROUTES.fundingRequest.list,
  APP_ROUTES.fundingRequest.add,
  APP_ROUTES.fundingRequest.detail,

  APP_ROUTES.fundingAgreement.list,
  APP_ROUTES.fundingAgreement.add,
  APP_ROUTES.fundingAgreement.detail,

  APP_ROUTES.transactionInvoice.add,
]


export const FUNDING_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS = [
  '/',
  '/view/login-account',
  APP_ROUTES.company.list,
  APP_ROUTES.company.add,
  APP_ROUTES.company.detail,

  APP_ROUTES.caseManager.list,
  APP_ROUTES.caseManager.add,
  APP_ROUTES.caseManager.detail,

  APP_ROUTES.specialist.list,
  APP_ROUTES.specialist.add,
  APP_ROUTES.specialist.detail,

  APP_ROUTES.client.list,
  APP_ROUTES.client.add,
  APP_ROUTES.client.detail,

  APP_ROUTES.staff.list,

  APP_ROUTES.user.list,

  APP_ROUTES.fundingRequest.list,
  APP_ROUTES.fundingRequest.add,
  APP_ROUTES.fundingRequest.detail,

  APP_ROUTES.fundingAgreement.list,
  APP_ROUTES.fundingAgreement.add,
  APP_ROUTES.fundingAgreement.detail,

  APP_ROUTES.transactionInvoice.list,
  APP_ROUTES.transactionInvoice.add,
  APP_ROUTES.transactionInvoice.detail,

  APP_ROUTES.fundingTransaction.list,
  APP_ROUTES.fundingTransaction.add,
  APP_ROUTES.fundingTransaction.detail,

  APP_ROUTES.disbursementFunding.list,
  APP_ROUTES.disbursementFunding.add,
  APP_ROUTES.disbursementFunding.detail,

  APP_ROUTES.serviceItem.list,
  APP_ROUTES.talkingPoint.list,
  APP_ROUTES.mailTemplate.list,

  APP_ROUTES.checkList.list,
  APP_ROUTES.checkList.detail,
  APP_ROUTES.checkList.add,

  APP_ROUTES.serviceProvider.list,
  APP_ROUTES.serviceProvider.add,
  APP_ROUTES.serviceProvider.detail,

  '/view/lookup-config',
  '/view/system-config',
  '/view/agreement-advance/print',
  '/view/funding-analysis-report',
  '/view/disbursement-funding-report',
  '/view/disbursement-print',
  '/view/repaymentinvoice-print',
  '/view/outstanding-statement-print',
  '/view/repaymentinvoice/outstanding-statement-print',
  '/view/emails-tracking',
  '/view/ad-hoc',
]

export const LMQ_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS = [
  '/',
  '/view/login-account',
  APP_ROUTES.company.list,
  APP_ROUTES.company.add,
  APP_ROUTES.company.detail,

  APP_ROUTES.staff.list,
  APP_ROUTES.user.list,

  APP_ROUTES.massTorts.list,
  APP_ROUTES.massTorts.detail,

  APP_ROUTES.fundingAgreement.list,
  APP_ROUTES.fundingAgreement.detail,

  APP_ROUTES.fundingTransaction.list,
  APP_ROUTES.fundingTransaction.add,
  APP_ROUTES.fundingTransaction.detail,

  APP_ROUTES.disbursementFunding.list,
  APP_ROUTES.disbursementFunding.add,
  APP_ROUTES.disbursementFunding.detail,

  '/view/lookup-config',
  APP_ROUTES.mailTemplate.list,

  APP_ROUTES.checkList.list,
  APP_ROUTES.checkList.add,
  APP_ROUTES.checkList.detail,

  '/view/system-config',

  '/view/emails-tracking',
  '/view/agreement-advance/print',
]
