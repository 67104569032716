import { queryClient } from './../baseService'
import { ApiRequest } from '../baseService'

const BaseRoute = 'app/clio-app'

const SendRequest = (method, url, data?) => {
  return ApiRequest.post(`${BaseRoute}/send-request`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetStatus = matterIds => {
  return ApiRequest.post(`${BaseRoute}/sync-statuses`, {
    matterIds,
  })
}

const GetMatterDetail = (id, tenantId?) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}`)
}

const GetContactDetail = id => {
  return SendRequest(
    'Get',
    `/contacts/${id}.json?fields=id,name,first_name,last_name,is_client,middle_name,type,primary_email_address`,
  )
}

const SyncClient = (matterId, pmsConfigId = null) => {
  return ApiRequest.post(`${BaseRoute}/sync-client`, {
    matterId,
    pmsConfigId,
  })
}

const SyncUser = (userId, pmsConfigId = null) => {
  return ApiRequest.post(`${BaseRoute}/sync-user`, {
    userId,
    pmsConfigId,
  })
}

const RegisterWebHooks = () => {
  return ApiRequest.post(`${BaseRoute}/register-webhooks`, {})
}

const GetCustomFieldSets = (params = {}) => {
  return queryClient.fetchQuery(
    ['clio_custom_field_sets'],
    () => {
      return ApiRequest.get(`${BaseRoute}/custom_field_sets`, { params: params })
    },
    {
      staleTime: 300 * 1000, // 5 minutes
    },
  )
}

const GetRegisteredHooks = (params = {}) => {
  return ApiRequest.get(`${BaseRoute}/register-webhooks`, {})
}

const API = {
  SendRequest,
  GetStatus,
  GetMatterDetail,
  SyncClient,
  SyncUser,
  RegisterWebHooks,
  GetCustomFieldSets,
  GetContactDetail,
  GetRegisteredHooks,
}
export default API
